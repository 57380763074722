;

(function (App) {
    if ('hu_HU' != App.Translator.locale) {
        return;
    }

    var messages = {
        "please choose": "kérlek válassz",
        "required": "kötelező",
        // cart
        "You can only enter a number as quantity!": "Csak számot adhatsz meg darabszámként!",
        "Product added to cart.": "Termék kosárba téve.",
        "Close": "Bezár",
        "Go to cart": "Ugrás a kosárhoz",
        "Product updated in cart.": "Termék frissítve a kosárban.",
        "Product deleted in cart.": "Termék törölve a kosárból.",
        "Place order": "Rendelés feladása",
        // booking
        "Please fill this field!": "Kérlek töltsd ki ezt a mezőt!",
        "The zip code need to be four number!": "Az irányítószámnak négy jegyűnek kell lennie!",
        "The zip code need to be six number!": "Az irányítószámnak hat jegyűnek kell lennie!",
        // marso maps
        "Your position": "A te pozíciód",
        "Go to the garage page": "Tovább a szerviz oldalára",
        "Closed": "Zárva",
        "Monday": "Hétfő",
        "Tuesday": "Kedd",
        "Wednesday": "Szerda",
        "Thursday": "Csütörtök",
        "Friday": "Péntek",
        "Saturday": "Szombat",
        "Sunday": "Vasárnap",
        "Please enter a valid e-mail!": "Kérlek helyes email címet adj meg!",
        // validator
        "This field is required!": "A mező kitöltése kötelező!",
        "This value is too short!": "A mező tartalma túl rövid!",
        "This value is too long!": "A mező tartalma túl hosszú!",
        "This value need to be %len% long!": "A mező tartalmának hossza %len% kell legyen!",
        "This value not a number!": "A mező tartalmának számnak kell lennie!",
        "This value is too low!": "A mező értéke túl kicsi!",
        "This value is too high!": "A mező értéke túl nagy!",
        "This value not an email address!": "A mező tartalma nem tűnik email címnek!",
        "This value is not valid!": "Helytelen adat!",
        "The format must be: %mask%": "A formátumnak egyeznie kell ezzel: %mask%",
        // rim search
        "coll": "coll",
        "choose brand": "válassz márkát",
        "choose type": "válassz típust",
        "choose model": "válassz modellt",
        "choose size": "válassz méretet",
        "No or indirect": "Nincs vagy indirekt",
        "Direct": "Direkt",
        // search
        "Error in debug logging, exiting.": "Error in debug logging, exiting.",
        "A valid template name was not specified.": "A valid template name was not specified.",
        "There was an issue with querying the server.": "Sajnos a szerver oldali hibába futottál.",
        "Results must be an array to use maxResults setting": "Results must be an array to use maxResults setting",
        "The method you called is not defined.": "The method you called is not defined.",
        "Cannot search. No source used, and Semantic API module was not included": "Cannot search. No source used, and Semantic API module was not included",
        "Your search returned no results": "A keresés nem hozott eredményt",
        // checkout
        "If you filled the company field, fill this field also!": "Ha megadtál cégnevet, akkor ezt a mezőt is ki kell töltened!",
        // wheel
        "Please choose a rim first!": "Kérlek válassz egy felnit először!",
        "Please choose a tyre first!": "Kérlek válassz egy abroncsot először!",
        "": ""

    };

    App.Translator.extendDictionary(messages);

})(App);
