;
(function (App) {
    if ('ro_RO' != App.Translator.locale) {
        return;
    }
    var messages = {
        "please choose": "vă rugăm alegeți",
        "required": "obligatorie",
        // cart
        "You can only enter a number as quantity!": "Se pot intruduce doar numere la cantitate!",
        "Product added to cart.": "Produs adăugat în coș.",
        "Close": "Închide",
        "Go to cart": "La coș",
        "Product updated in cart.": "Produs actualizat în coș.",
        "Product deleted in cart.": "Produs șters din coș.",
        "Place order": "Plasare comandă",
        // booking
        "Please fill this field!": "Vă rugăm completați acest câmp!",
        "The zip code need to be four number!": "Codul poștal trebuie să conțină patru numere!",
        "The zip code need to be six number!": "Codul postal trebuie să conțină șase numere!",
        // marso maps
        "Your position": "Poziția ta",
        "Go to the garage page": "Mai departe la pagina serviceului",
        "Closed": "Închis",
        "Monday": "Luni",
        "Tuesday": "Marți",
        "Wednesday": "Miercuri",
        "Thursday": "Joi",
        "Friday": "Vineri",
        "Saturday": "Sâmbătă",
        "Sunday": "Duminică",
        "Please enter a valid e-mail!": "Vă rugăm introduceți o adresă de email validă!",
        // validator
        "This field is required!": "Completarea campului este obligatorie!",
        "This value is too short!": "Valoare prea scurta!",
        "This value is too long!": "Valoare prea lunga!",
        "This value need to be %len% long!": "Lungimea trebuie sa fie %len%!",
        "This value not a number!": "Valoarea introdusa trebuie sa fie numar!",
        "This value is too low!": "Valoarea este prea mica!",
        "This value is too high!": "Valoarea este prea mare!",
        "This value not an email address!": "Valoarea introdusa nu este adresa de email!",
        "This value is not valid!": "Date incorecte!",
        "The format must be: %mask%": "Formatul trebuie să fie: %mask%",
        // rim search
        "coll": "țoli",
        "choose brand": "alege marca",
        "choose type": "alege tip",
        "choose model": "alege model",
        "choose size": "alege dimensiune",
        "No or indirect": "Fara sau indirect",
        "Direct": "Direct",
        // search
        "Error in debug logging, exiting.": "Error in debug logging, exiting.",
        "A valid template name was not specified.": "A valid template name was not specified.",
        "There was an issue with querying the server.": "Este o problemă în interogarea serverului.",
        "Results must be an array to use maxResults setting": "Results must be an array to use maxResults setting",
        "The method you called is not defined.": "The method you called is not defined.",
        "Cannot search. No source used, and Semantic API module was not included": "Cannot search. No source used, and Semantic API module was not included",
        "Your search returned no results": "Căutarea nu a găsit niciun rezultat",
        // checkout
        "If you filled the company field, fill this field also!": "Dacă ai completat nume firmă, trebuie completat și acest câmp!",
        // wheel
        "Please choose a rim first!": "Alege prima dată o jantă!",
        "Please choose a tyre first!": "Alege prima dată o anvelopă!",
        "": ""
    };

    App.Translator.extendDictionary(messages);

})(App);
