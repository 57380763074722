;
(function (App) {
    var messages = {
        "please choose": "please choose",
        "required": "required",
        // cart
        "You can only enter a number as quantity!": "You can only enter a number as quantity!",
        "Product added to cart.": "Product added to cart.",
        "Close": "Close",
        "Go to cart": "Go to cart",
        "Product updated in cart.": "Product updated in cart.",
        "Product deleted in cart.": "Product deleted in cart.",
        "Place order": "Place order",
        // booking
        "Please fill this field!": "Please fill this field!",
        "The zip code need to be four number!": "The zip code need to be four number!",
        "The zip code need to be six number!": "The zip code need to be six number!",
        // marso maps
        "Your position": "Your position",
        "Go to the garage page": "Go to the garage page",
        "Closed": "Closed",
        "Monday": "Monday",
        "Tuesday": "Tuesday",
        "Wednesday": "Wednesday",
        "Thursday": "Thursday",
        "Friday": "Friday",
        "Saturday": "Saturday",
        "Sunday": "Sunday",
        "Please enter a valid e-mail!": "Please enter a valid e-mail!",
        // validator
        "This field is required!": "This field is required!",
        "This value is too short!": "This value is too short!",
        "This value is too long!": "This value is too long!",
        "This value need to be %len% long!": "This value need to be %len% long!",
        "This value not a number!": "This value not a number!",
        "This value is too low!": "This value is too low!",
        "This value is too high!": "This value is too high!",
        "This value not an email address!": "This value not an email address!",
        "This value is not valid!": "This value is not valid!",
        "The format must be: %mask%": "The format must be: %mask%",
        // rim search
        "coll": "coll",
        "choose brand": "choose brand",
        "choose type": "choose type",
        "choose model": "choose model",
        "choose size": "choose size",
        "No or indirect": "N or indirect",
        "Direct": "Direct",
        // search
        "Error in debug logging, exiting.": "Error in debug logging, exiting.",
        "A valid template name was not specified.": "A valid template name was not specified.",
        "There was an issue with querying the server.": "There was an issue with querying the server.",
        "Results must be an array to use maxResults setting": "Results must be an array to use maxResults setting",
        "The method you called is not defined.": "The method you called is not defined.",
        "Cannot search. No source used, and Semantic API module was not included": "Cannot search. No source used, and Semantic API module was not included",
        "Your search returned no results": "Your search returned no results",
        // checkout
        "If you filled the company field, fill this field also!": "If you filled the company field, fill this field also!",
        // wheel
        "Please choose a rim first!": "Please choose a rim first!",
        "Please choose a tyre first!": "Please choose a tyre first!",
        "": ""
    };

    App.Translator.extendDictionary(messages);

})(App);
