// add sentry
import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from "@sentry/tracing";
if (!/.*[\.]?localhost|.*[\.]?local\.hu/i.test(window.location.host)) {
    Sentry.init({
        dsn: 'https://ca9db50275ed42d7b77f9201102cc033@o310262.ingest.sentry.io/1775631',
        integrations: [new TracingIntegrations.BrowserTracing()],
        tracesSampleRate: 0.1,
        environment: window.location.host,
        // debug: true,
        allowUrls: [
            new RegExp(window.location.host.replace(/www\.?/i, '').replace(/\./g, '\\.'), 'i'),
            new RegExp(window.location.host.replace(/\./g, '\\.'), 'i'),
        ],
        // @ts-ignore
        release: 'kiskershop@' + packageVersion
    });
};
import './../public/resources/application/static.js';

import Cookies from 'js-cookie';
window.Cookies = Cookies;

import 'vanilla-cookieconsent/src/cookieconsent.js';

import jQuery from 'jquery';
window['j' + 'Query'] = jQuery; // webpack hack
window.$ = jQuery;

import './../public/resources/jquery.mobile-events/jquery.mobile-events.js';
import './../public/resources/browser-storage.js';
import './../public/resources/application/core.js';

// translations
import './../public/resources/application/translations/messages.js';
import './../public/resources/application/translations/messages.hu_HU.js';
import './../public/resources/application/translations/messages.ro_RO.js';
import './../public/resources/application/translations/messages.sr_RS.js';

import './../public/theme/marso/assets/javascripts/semantic-ui.js';
import './../public/theme/marso/assets/javascripts/scroller.js';
import './../public/theme/marso/assets/javascripts/app.js';
import './../public/theme/marso/assets/javascripts/header.js';
import './../public/theme/marso/assets/javascripts/alcar.js';
import './../public/theme/marso/assets/javascripts/countdown.js';
import './../public/extensions/local/marso/product-search/freehand-product-search.js';

// modules
import './../public/extensions/local/marso/user-cars/usercar.js';
import './../public/extensions/local/marso/newsletter/newsletter.js';

/* page specific modules */

// home.twig
import './../public/extensions/local/marso/banners-contents/banners-contents.js';

// cart.twig, contact.twig, marso-booking.twig, marso-checkout.twig, rim-search.twig, my-cars.twig
import './../public/theme/marso/assets/javascripts/validator.js';

// cart.twig, marso-checkout-summary.twig, marso-checkout.js
import './../public/extensions/local/marso/marso-coupons/marso-coupons.js';

// cart.twig
import './../public/theme/marso/assets/javascripts/cart.js';
import './../public/extensions/local/marso/services-contents/services-contents.js';

// cart.twig, category-search.twig, product-page.twig
import './../public/extensions/local/marso/manufacturers-stock-updater/manufacturers-stock-updater.js';

// _account_checkin_template.js
import './../public/theme/marso/assets/javascripts/login.js';

// error404.twig, contact.twig, marso-booking.twig, rim-search.twig, my-cars.twig
import './../public/extensions/local/marso/contact/contact.js';

// _accountPage.twig
import './../public/theme/marso/assets/javascripts/account.js';

// discount-landing.twig, discount-normal.twig
import './../public/extensions/local/marso/discounts-contents/discount-contents.js';

// discounts.twig, four-season-discounts.twig, summer-discounts.twig, winter-discounts.twig
import './../public/extensions/local/marso/discounts-contents/discounts-contents.js';

// manufacturer.twig
import './../public/extensions/local/marso/manufacturers-contents/manufacturers-contents.js';

// marso-booking.twig, marso-checkout.twig, my-cars.twig
import './../public/extensions/local/marso/user-cars/carform.js';

// marso-booking.twig
import './../public/extensions/local/marso/marso-booking/marso-booking.js';

// marso-checkout-offer-request.twig
import './../public/extensions/local/marso/marso-checkout/marso-offer-request.js';

// marso-checkout-summary.twig
import './../public/extensions/local/marso/marso-checkout/marso-checkout-summary.js';

// marso-checkout.js
import CheckoutJS from './../public/extensions/local/marso/marso-checkout/marso-checkout.js';
if ($('body').hasClass('page-checkout')) {
    CheckoutJS();
}

// example for https://symfony.com/doc/current/frontend/encore/code-splitting.html
// if ($('body').hasClass('page-checkout')) {
//     import('./../public/extensions/local/marso/marso-checkout/marso-checkout.js').then(({default: CheckoutJS}) => { // jshint ignore:line
//         CheckoutJS();
//     });
// }

// news.twig
import './../public/extensions/local/marso/news-contents/news-contents.js';

// category-search.twig
import ProductSidebarSearchJS from './../public/extensions/local/marso/product-search/product-sidebar-search.js';
if ($('body').hasClass('category-search-page')) {
    ProductSidebarSearchJS();
}

// index-search.twig
import ProductSearchJS from './../public/extensions/local/marso/product-search/product-search.js';
if ($('body').hasClass('search-index-page')) {
    ProductSearchJS();
}

// product-page.twig
import './../public/theme/marso/assets/javascripts/product.js';

// rim-search.twig
import './../public/extensions/local/marso/rim-search/rim-search.js';

// service-sites.twig, service-site.twig
import SnazzyInfoWindow from 'snazzy-info-window/dist/snazzy-info-window'; // by default min file is loaded. we need full file for references in dev env
window.SnazzyInfoWindow = SnazzyInfoWindow;
import './../public/theme/marso/assets/javascripts/maps.js';

// service-site.twig
import './../public/extensions/local/marso/service-sites-contents/service-sites-contents.js';

// wheel-wizard.twig, wheel-wizard-choose-car.twig, wheel-wizard-choose-rim.twig, wheel-wizard-choose-tyre.twig, wheel-wizard-completed.twig
import './../public/extensions/local/marso/wheel-wizard/wheel-wizard.js';

// dot-tyres.twig
import './../public/extensions/local/marso/dot-tyres/dot-tyres.js';
