function ServiceBookingForm() {
    this.forms = {};
    this.forceSend = false;
}

ServiceBookingForm.prototype.addForm = function(name, object) {
    this.forms[name] = object;
};

ServiceBookingForm.prototype.next = function(callingFormName) {
    switch (callingFormName) {
        case 'carForm':
            this.forms.garageForm.initStep();
            break;
        case 'garageForm':
            this.forms.serviceProductForm.initStep();
            break;
        case 'serviceProductForm':
            this.forms.dateTimeForm.initStep();
            break;
        case 'dateTimeForm':
            this.forms.bookingPreview.initStep();
            break;
        default: break;
    }
};

ServiceBookingForm.prototype.back = function(callingFormName) {
    switch (callingFormName) {
        case 'carForm':
            this.forms.garageForm.backStep();
        case 'garageForm':
            this.forms.serviceProductForm.backStep();
        case 'serviceProductForm':
            this.forms.dateTimeForm.backStep();
        case 'dateTimeForm':
            this.forms.bookingPreview.backStep();
        default: break;
    }
};

ServiceBookingForm.prototype.processHeader = function() {
    var headerMenuId;
    var keys = Object.keys(this.forms);
    var chainBreaked = false;
    for (var i = 0, len = keys.length; i < len; ++i) {
        headerMenuId = '#' + keys[i] + 'Menu';
        if (chainBreaked) {
            $(headerMenuId).removeClass('active success');
            continue;
        }
        if (this.forms[keys[i]].isValid()) {
            $(headerMenuId).addClass('active success');
        } else {
            // the chain break
            chainBreaked = true;
            $(headerMenuId).removeClass('active success');
            if (
                (typeof this.forms[keys[i]].block !== 'undefined' && this.forms[keys[i]].block.hasClass('active'))
                || (typeof this.forms[keys[i]].form !== 'undefined' && this.forms[keys[i]].form.hasClass('active'))
            ) {
                // set the chain last element to active
                $(headerMenuId).addClass('active');
            }
        }
    }
};

ServiceBookingForm.prototype.init = function() {
    // need to set pointer point to below
    $.fn.form.settings.templates.prompt = function(errors) {
        return $('<div/>')
            .addClass('ui basic red pointing below prompt label')
            .html(errors[0])
            ;
    };

    // form submit
    $('#booking-button').on('click', function(event) {
        serviceBookingForm.forceSend = true;
        $('#notice').val($('#booking_notice').val());
        $('#booking-form').submit();
        serviceBookingForm.forceSend = false;
    });

    var keys = Object.keys(this.forms);
    for (var i = 0, len = keys.length; i < len; ++i) {
        this.forms[keys[i]].init();
    }

    // init the header
    this.processHeader();
};

ServiceBookingForm.prototype.validate = function() {
    var keys = Object.keys(this.forms);
    var len = keys.length;
    if (0 < len) {
        for (var i = 0; i < len; ++i) {
            if (!this.forms[keys[i]].isValid()) {
                return false;
            }
        }
    }
    return true;
};

ServiceBookingForm.prototype.process = function() {
    this.processHeader();
    // activate or deactivate the button
    if (this.validate()) {
        $('#booking-button-block').removeClass('invisible');
    } else {
        $('#booking-button-block').addClass('invisible');
    }
};

var serviceBookingForm = new ServiceBookingForm();

(function ($,App) {

    $('.booking-process-menu .jump').on('click', function(event) {
        var selector = $(event.currentTarget).data('goto');
        App.Utils.scrollTo($(selector), -80);
        event.preventDefault();
    });

})(jQuery, App);

// car form
(function ($,App,serviceBookingForm) {
    if (!$('body').hasClass('page-booking')) {
        return;
    }

    App.CarForm.validFields = [];

    App.CarForm.fieldsNeedToValid = ['license_plate_number', 'brand_id', 'type_id', 'model_id'];

    App.CarForm.isValid = function() {
        return this.checkFormValidity('');
    };

    App.CarForm.checkFormValidity = function(lastChangedId) {
        var len = this.fieldsNeedToValid.length;
        for (var i = 0; i < len; ++i) {
            if (-1 == $.inArray(this.fieldsNeedToValid[i], this.validFields)) {
                return false;
            }
        }

        if (this.needTyreHotelSearch) {
            if (!this.tyreHotelStatus) {
                return false;
            }
        }
        return true;
    };

    App.CarForm.addValidField = function(fieldId) {
        var pos = $.inArray(fieldId, this.validFields);
        if (-1 == pos) {
            this.validFields.push(fieldId);
            return true;
        }
        return false;
    };

    App.CarForm.removeValidField = function(fieldId) {
        var pos = $.inArray(fieldId, this.validFields);
        if (-1 < pos) {
            this.validFields.splice(pos, 1);
            return true;
        }
        return false;
    };

    App.CarForm.reset = function() {
        var self = this;
        $.each(this.fields, function(i, el) {
            el.element.parents('.dropdown').dropdown('set text', '');
            el.element.parents('.dropdown').dropdown('set value', '');
            self.removeValidField(i);
            el.element.trigger('change').trigger('blur');
        });
        this.loadImage();
    };

    App.CarForm.fillFromUserCars = function() {
        var carData = this.form.find('#car_id option:selected').data(),
            self = this;
        $.each(carData, function(id, val) {
            if (typeof self.fields[id] !== 'undefined') {
                self.fields[id].element.val(val);
                self.removeValidField(id);
                self.fields[id].element.trigger('change').trigger('blur');
            }
        });
        this.initDefaultValues();
        this.loadImage(carData.image);
    };

    App.CarForm.parentInit = App.CarForm.init;

    App.CarForm.init = function (selector) {
        selector = selector || '#booking-form';

        this.parentInit(selector);

        var self = this;

        self.tyreHotelStatus = false;
        self.needTyreHotelSearch = false;
        self.needBroughtTyreWheel = false;

        delete this.fields.name;
        delete this.fields.tyre_size;
        delete this.fields.mileage;
        delete this.fields.international_vehicle_registration_code;
        delete this.fields.summer_tyre_front_size;
        delete this.fields.summer_tyre_rear_size;
        delete this.fields.winter_tyre_front_size;
        delete this.fields.winter_tyre_rear_size;
        this.fields.save_car = {
            element: self.form.find('#save_car'),
            isValid: function() {
                return true;
            }
        };

        $.each(this.fields, function(id, el) {
            el.element.on('change blur', function() {
                if ('license_plate_number' == id) {
                    self.resetTyreHotelForm();
                    self.resetBroughtTyreWheel();
                }
                if (self.isValidField(id)) {
                    if (serviceBookingForm.forceSend) {
                        return;
                    }
                    self.addValidField(id);
                    if (self.checkFormValidity(id)) {
                        self.form.addClass('success');
                        serviceBookingForm.next('carForm');
                    } else {
                        if (self.form.hasClass('success')) {
                            self.form.removeClass('success');
                            serviceBookingForm.back('carForm');
                        }
                    }
                    serviceBookingForm.process();
                } else {
                    self.removeValidField(id);
                    if (!self.checkFormValidity(id)) {
                        if (self.form.hasClass('success')) {
                            self.form.removeClass('success');
                            serviceBookingForm.back('carForm');
                        }
                    } else {
                        self.form.addClass('success');
                        serviceBookingForm.next('carForm');
                    }
                    serviceBookingForm.process();
                }
            });
        });

        if ('' == this.form.find('#license_plate_number').val()) {
            if (0 < this.form.find('#car_id').val()) {
                this.fillFromUserCars();
                if (this.form.find('#save_car').prop('checked')) {
                    this.form.find('#save_car').prop('checked', false);
                }
            }
        }

        this.form.find('#car_id').on('change', function(event){
            var value = $(event.currentTarget).val();
            if (0 == value) {
                self.reset();
            } else {
                self.fillFromUserCars();
                if (self.form.find('#save_car').prop('checked')) {
                    self.form.find('#save_car').prop('checked', false);
                }
            }
        });

        this.form.find('.message .close')
            .on('click', function() {
                $(this)
                    .closest('.message')
                    .transition('slide down')
                ;
        });

        if ($('#tyre-hotel').length) {
            this.needTyreHotelSearch = true;
            this.initTyreHotel();
        }

        if ($('#brought_tyre_wheel').length) {
            this.needBroughtTyreWheel = true;
            this.initBroughtTyreWheel();
        }
    };

    App.CarForm.resetBroughtTyreWheel = function() {
        if ($('#brought_tyre_wheel').prop('checked')) {
            $('#brought_tyre_wheel').prop('checked', false);
        }
    };

    App.CarForm.initBroughtTyreWheel = function() {

        if (this.needTyreHotelSearch) {
            $('#want-tyre-hotel-search').on('click', (event) => {
                this.resetBroughtTyreWheel();
            });
        }

        $('#brought_tyre_wheel').on('change', (e) => {
            if ($('#brought_tyre_wheel').prop('checked')) {
                if (this.needTyreHotelSearch) {
                    this.tyreHotelStatus = true;
                    $('#tyre-hotel').hide();
                }
                // serviceBookingForm.process();
                if (this.isValid()) {
                    serviceBookingForm.next('carForm');
                }
            } else {
                if (this.needTyreHotelSearch) {
                    this.tyreHotelStatus = false;
                    $('#tyre-hotel').show();
                }
                serviceBookingForm.back('carForm');
            }
        });

    };

    App.CarForm.initTyreHotel = function() {
        var self = this;
        $('#want-tyre-hotel-search').on('click', function(event) {
            self.wantTyreHotelSearch();
        });

        $('#do-not-want-tyre-hotel-search').on('click', function(event) {
            self.doNotWantTyreHotelSearch();
        });

        $('#tyre-hotel .form').on('change', '.tyre_set_id', function(event){
           // goto next step
            self.tyreHotelStatus = true;
            // $('#zip-code-form').addClass('invisible');
            var input = $(event.currentTarget).one();
            $('#tyre_hotel_set_id').val(input.val());
            $('#tyre_hotel_garage_id').val(input.data('garage_id'));
            $('#tyre_hotel_company_code').val(input.data('company_code'));
            $('#tyre_hotel_service_type').val(input.data('service_type'));
            $('#tyre_hotel_car_type').val(input.data('car_type'));
            $('#tyre_hotel_diameter').val(input.data('diameter'));

            if (self.isValid()) {
                serviceBookingForm.next('carForm');
            } else {
                serviceBookingForm.back('carForm');
            }
        });
    };

    App.CarForm.resetTyreHotelForm = function () {
        this.tyreHotelStatus = false;
        serviceBookingForm.back('carForm');
        $('#tyre_hotel_set_id').val('');
        $('#tyre_hotel_garage_id').val('');
        $('#tyre_hotel_company_code').val('');
        $('#tyre_hotel_diameter').val('');
        $('#tyre_hotel_service_type').val('');
        $('#tyre_hotel_car_type').val('');
        $('#want-tyre-hotel-search').removeClass('green').removeClass('warning');
        $('#do-not-want-tyre-hotel-search').removeClass('green');
        $('#no-license-plate-number-error').addClass('invisible');
        $('#tyre-hotel .status.sip').removeClass('loading').addClass('invisible');
        $('#tyre-hotel .status.empty').addClass('invisible');
        $('#tyre-hotel .status.after-search-message').addClass('invisible');
        $('#tyre-hotel .status.message').addClass('invisible');
        $('#tyre-hotel .form').empty().addClass('invisible');
    };

    App.CarForm.wantTyreHotelSearch = function () {
        this.resetTyreHotelForm();
        // need a validation
        $('#do-not-want-tyre-hotel-search').removeClass('green');
        var url = $('#want-tyre-hotel-search').data('url');
        var license_plate_number = $('#license_plate_number').val();
        if (!license_plate_number) {
            // display error
            $('#want-tyre-hotel-search').addClass('warning');
            $('#no-license-plate-number-error').removeClass('invisible');
            return;
        }
        $('#want-tyre-hotel-search').prop('disabled', true);
        $('#do-not-want-tyre-hotel-search').prop('disabled', true);
        // begin search
        $('#tyre-hotel .status.sip').addClass('loading').removeClass('invisible');
        $.ajax({
            type: "POST",
            dataType: 'json',
            url: url,
            data: { license_plate_number: license_plate_number },
            success: function (data) {
                if (data.error_string && ('' != data.error_string)) {
                    // display error
                    return;
                }
                if (data.result) {
                    var keys = Object.keys(data.result.sets);
                    if (keys && keys.length) {
                        keys.forEach(function (i) {
                            var set = data.result.sets[i];
                            var skeleton = $('#tyre-hotel .skeleton .tyre-hotel-selector-radio');
                            var element = skeleton.clone();
                            element.find('.tyre_set_id').val(set.set_id)
                                .data('company_code', set.company_code)
                                .data('garage_id', set.garage_id)
                                .data('diameter', set.diameter)
                                .data('service_type', set.service_type)
                                .data('car_type', set.car_type);
                            element.find('.field-label').html(set.name.join(', ') + ' - ' + set.location_name);
                            $('#tyre-hotel .form').append(element);
                        });
                        $('#tyre-hotel .form').removeClass('invisible');
                    } else {
                        $('#tyre-hotel .status.empty').removeClass('invisible');
                    }
                }
                $('#want-tyre-hotel-search').removeClass('warning').addClass('green');
            },
            error: function (data) {
                // TODO error message handling
                // $('#want-tyre-hotel-search').addClass('warning');
            },
            complete: function () {
                $('#tyre-hotel .status.sip').removeClass('loading').addClass('invisible');
                $('#want-tyre-hotel-search').prop('disabled', false);
                $('#do-not-want-tyre-hotel-search').prop('disabled', false);
                $('#tyre-hotel .status.after-search-message').removeClass('invisible');
            }
        });
    };

    App.CarForm.doNotWantTyreHotelSearch = function () {
        serviceBookingForm.back('carForm');
        this.resetTyreHotelForm();
        $('#zip-code-form').removeClass('invisible');
        this.tyreHotelStatus = true;
        $('#do-not-want-tyre-hotel-search').addClass('green');
        $('#want-tyre-hotel-search').removeClass('green');
        if (this.isValid()) {
            serviceBookingForm.next('carForm');
        }
    };

    serviceBookingForm.addForm("carForm", App.CarForm);
})(jQuery, App, serviceBookingForm);

// garage form
(function ($,App,serviceBookingForm) {

    function GarageForm() {
        this.block = $('#booking-choose-garage');
    }

    GarageForm.prototype.isValid = function() {
        var garageId = $('#garage_id').val();
        if (0 < garageId) {
            return true;
        }
        return false;
    };

    GarageForm.prototype.init = function() {
        // try to get current position
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position){
                if (position.coords.latitude && position.coords.longitude) {
                    $('#latitude').val(position.coords.latitude);
                    $('#longitude').val(position.coords.longitude);
                }
            });
        }

        var frame = this.block.find('.hs-frame');
        if (frame.length) {
            frame.find('.hs-left-button').on('click', function(event){
                var items = frame.find('.hs-items');
                var shift = App.Utils.calculateHorizontalScrollShift(items);
                App.Utils.scrollLeft(items, shift, null, items);
            });

            frame.find('.hs-right-button').on('click', function(event){
                var items = frame.find('.hs-items');
                var shift = App.Utils.calculateHorizontalScrollShift(items);
                App.Utils.scrollRight(items, shift, null, items);
            });

            frame.find('.hs-items').on('swiperight', function(event) {
                var items = frame.find('.hs-items');
                var shift = App.Utils.calculateHorizontalScrollShift(frame.find('.hs-items'));
                App.Utils.scrollLeft(items, shift, null, items);
            }).on('swipeleft', function(event) {
                var items = frame.find('.hs-items');
                var shift = App.Utils.calculateHorizontalScrollShift(frame.find('.hs-items'));
                App.Utils.scrollRight(items, shift, null, items);
            })
            ;
        }

        $('#booking-choose-garage').on('click','.more-button',function(event){
            var content = $(event.currentTarget).parents('.services-list');
            if (content.hasClass('opened')) {
                content.removeClass('opened');
            } else {
                content.addClass('opened');
            }
        });

        $('#booking-choose-garage').on('click','.choose-button',function(event){
            $('#booking-choose-garage .garage').removeClass('selected');
            var garage = $(event.currentTarget).parents('.garage');
            serviceBookingForm.back('garageForm');
            if (garage.length) {
                garage.addClass('selected');
                var garageOnlineBooking = garage.data('garage-online-booking');
                $('#garage_online_booking').val(garageOnlineBooking);
                var garageId = garage.data('garage-id');
                $('#garage_id').val(garageId).trigger('change');
            }
        });

        $('#garage_id').on('change',function(event){
            var input = $(event.currentTarget);
            if (self.isValid()) {
                // maybe the zip form is filled, remove the value
                $('#zip').val('');
                $('#zip-search').val('');
                $('#booking-choose-garage').addClass('success');
                serviceBookingForm.next('garageForm');
            } else {
                if ($('#booking-choose-garage').hasClass('success')) {
                    $('#booking-choose-garage').removeClass('success');
                    serviceBookingForm.back('garageForm');
                }
            }
            serviceBookingForm.process();
        });

        $('#zip-search-button').on('click', function(event) {
            var searchEl = $('#zip-search'),
                zip = searchEl.val(),
                mask = searchEl.data('mask'),
                re = new RegExp(mask, 'g');
            if (null === zip.match(re)) {
                $('#zip-code-form .prompt').removeClass('invisible');
            } else {
                $('#zip').val(zip).trigger('change');
            }
        });

        $('#zip-search').on('change',function(event){
            $('#zip-code-form .prompt').addClass('invisible');
        });

        $('#zip-search').on('keyup', function(event) {
            if (13 == event.which) {
                $('#zip-search-button').trigger('click');
            }
        });

        $('#zip').on('change',function(event) {
            var input = $(event.currentTarget);
            if ('' == input.val()) {
                // when zip changed to empty, do nothing
            } else {
                //serviceBookingForm.back('carForm');
                self.loadGarages(true);
            }
        });

    };

    GarageForm.prototype.loadGarages = function(searchByZip) {
        // activate garages box and send ajax request
        serviceBookingForm.process();
        $('#booking-choose-garage .garages').empty();
        App.Utils.loading($('#booking-choose-garage .garages'), 'six wide centered center aligned column', 'huge', 200, 200);
        if (!searchByZip) {
            $('#zip').val('');
            $('#city').val('');
        }
        // send request
        var formUrl = $('#booking-choose-garage').data('url');
        var form = $('#booking-form');
        $.ajax({
            type: "POST",
            dataType: 'html',
            url: formUrl,
            data: form.serialize(),
            success: function (data) {
                $('#booking-choose-garage .garages').empty();
                $('#booking-choose-garage .garages').html(data);
                var garage = $('#booking-choose-garage .garage.selected');
                if (garage.length) {
                    var garageOnlineBooking = garage.data('garage-online-booking');
                    $('#garage_online_booking').val(garageOnlineBooking);
                    var garageId = garage.data('garage-id');
                    $('#garage_id').val(garageId).trigger('change');
                }
            },
            error: function (data) {
            }
        });
    };

    GarageForm.prototype.reset = function() {
        $('#zip').val('');
        $('#city').val('');
        $('#garage_id').val('');
        $('#garage_online_booking').val('');
    };

    GarageForm.prototype.initStep = function() {
        this.reset();
        this.block.addClass('active');
        if (!this.isValid()) {
            if (!$('#booking-choose-garage .garages .garage').length) {
                this.loadGarages(false);
            }
            App.Utils.scrollTo(self.block, -40);
        } else {
            serviceBookingForm.next('garageForm');
        }
    };

    GarageForm.prototype.backStep = function() {
        this.reset();
        $('#booking-choose-garage').removeClass('success active');
        $('#booking-choose-garage .garages').empty();
    };

    var self = new GarageForm();
    serviceBookingForm.addForm("garageForm", self);

})(jQuery, App, serviceBookingForm);


// service product form
(function ($,App,serviceBookingForm) {

    function ServiceProductForm() {
        this.block = $('#booking-choose-service');
    }

    ServiceProductForm.prototype.isValid = function() {
        var productSku = $('#product_sku').val();
        if ('' != productSku) {
            return true;
        }
        return false;
    };

    ServiceProductForm.prototype.init = function() {

        var frame = this.block.find('.hs-frame');
        if (frame.length) {
            frame.find('.hs-left-button').on('click', function(event){
                var items = frame.find('.hs-items');
                var shift = App.Utils.calculateHorizontalScrollShift(items);
                App.Utils.scrollLeft(items, shift, null, items);
            });

            frame.find('.hs-right-button').on('click', function(event){
                var items = frame.find('.hs-items');
                var shift = App.Utils.calculateHorizontalScrollShift(items);
                App.Utils.scrollRight(items, shift, null, items);
            });

            frame.find('.hs-items').on('swiperight', function(event) {
                var items = frame.find('.hs-items');
                var shift = App.Utils.calculateHorizontalScrollShift(frame.find('.hs-items'));
                App.Utils.scrollLeft(items, shift, null, items);
            }).on('swipeleft', function(event) {
                var items = frame.find('.hs-items');
                var shift = App.Utils.calculateHorizontalScrollShift(frame.find('.hs-items'));
                App.Utils.scrollRight(items, shift, null, items);
            })
            ;
        }

        $('#booking-choose-service').on('click','.choose-button',function(event){
            $('#booking-choose-service .service').removeClass('selected');
            var service = $(event.currentTarget).parents('.service');
            serviceBookingForm.back('serviceProductForm');
            if (service.length) {
                service.addClass('selected');
                var serviceId = service.data('service-id');
                $('#service_id').val(serviceId);
                var productSku = service.data('product-sku');
                $('#product_sku').val(productSku).trigger('change');
            }
        });

        $('#product_sku').on('change',function(event){
            //var input = $(event.currentTarget);
            if (self.isValid()) {
                $('#booking-choose-service').addClass('success');
                serviceBookingForm.next('serviceProductForm');
            } else {
                if ($('#booking-choose-service').hasClass('success')) {
                    $('#booking-choose-service').removeClass('success');
                    serviceBookingForm.back('serviceProductForm');
                }
            }
            serviceBookingForm.process();
        });

        $('#service_diameter').on('change', function (event) {
            var diameter = $(event.currentTarget).val();
            var car_type, c;
            c = $('#connected_product_car_type').val();
            if (c) {
                car_type = c;
            }
            c = $('#tyre_hotel_car_type').val();
            if (c) {
                car_type = c;
            }

            self.liveFilterItems(diameter, car_type);
        });
    };

    ServiceProductForm.prototype.liveFilterItems = function(diameter, carType) {
        var items = this.block.find('.hs-frame .hs-item');

        items.removeClass('invisible');
        if (diameter) {
            items.not('.diameter-' + diameter).addClass('invisible');
            items.filter('.diameter-' + diameter).removeClass('invisible');
            // research visible items for carType filter
            items = this.block.find('.hs-frame .hs-item:not(.invisible)');
        }

        if (carType) {
            items.not('.car_type-' + carType).addClass('invisible');
            items.filter('.car_type-' + carType).removeClass('invisible');
        }
    };

    ServiceProductForm.prototype.loadServiceProducts = function() {
        $('#booking-choose-service .services').empty();
        App.Utils.loading($('#booking-choose-service .services'), 'six wide centered center aligned column', 'huge', 200, 200);
        if (!$('#booking-choose-service').hasClass('active')) {
            $('#booking-choose-service').addClass('active');
        }
        // send request
        var formUrl = $('#booking-choose-service').data('url');
        var form = $('#booking-form');
        $.ajax({
            type: "POST",
            dataType: 'html',
            url: formUrl,
            data: form.serialize(),
            success: function (data) {
                $('#booking-choose-service .services').empty();
                $('#booking-choose-service .services').html(data);
            },
            error: function (data) {
            },
            complete: function() {
                var showDiameterSelector = true;
                var d;
                d = $('#connected_product_diameter').val();
                if (d) {
                    $('#service_diameter').val(d);
                    showDiameterSelector = false;
                }

                d = $('#tyre_hotel_diameter').val();
                if (d) {
                    $('#service_diameter').val(d);
                    showDiameterSelector = false;
                }
                $('#service_diameter').trigger('change');

                if (showDiameterSelector) {
                    $('#service-diameter-selector').removeClass('invisible');
                } else {
                    $('#service-diameter-selector').addClass('invisible');
                }
            }
        });
    };

    ServiceProductForm.prototype.initStep = function() {
        this.block.addClass('active');
        if (!this.isValid()) {
            if (!$('#booking-choose-service .services .service').length) {
                this.loadServiceProducts();
                App.Utils.scrollTo(self.block, -50);
            }
        } else {
            serviceBookingForm.next('serviceProductForm');
        }
    };

    ServiceProductForm.prototype.backStep = function() {
        $('#product_sku').val('').trigger('change');
        $('#booking-choose-service .services').empty();
        $('#booking-choose-service').removeClass('active');
    };

    var self = new ServiceProductForm();
    serviceBookingForm.addForm("serviceProductForm", self);

})(jQuery, App, serviceBookingForm);

// datetime form
(function ($,App,serviceBookingForm) {

    function DateTimeForm() {
        this.block = $('#booking-choose-datetime');
    }

    DateTimeForm.prototype.isValid = function() {
        // depend on garage form, because depend on the online variable
        // which only valid if the garage form valid
        if (!serviceBookingForm.forms['garageForm'].isValid()) {
            // when the garage form is not valid, this must be invalid
            return false;
        }
        var online = $('#garage_online_booking').val();
        var date = $('#date').val();
        var time = $('#time').val();
        if (1 == online) {
            if (('' == date) || ('' == time)) {
                return false;
            }
        }
        return true;
    };

    DateTimeForm.prototype.init = function() {

        $('#booking-choose-datetime').on('click','#date-selector button.enabled',function(event){
            // click on date button
            var button = $(event.currentTarget);

            $('#date-selector .pointing.below.basic').removeClass('pointing below basic');
            button.addClass('pointing below basic');
            var day = button.data('day');

            $('#time-selector .time.column').addClass('invisible');
            $('#date').val('');
            $('#time').val('');
            $('#time-selector .button.time.column.selected').removeClass('selected');
            $('#time-selector .time.column.' + day).removeClass('invisible');
            serviceBookingForm.back('dateTimeForm');
            serviceBookingForm.process();
        });

        $('#booking-choose-datetime').on('click','#date-selector .next-button',function(event){
            var button = $(event.currentTarget);
            $('#page').val(button.data('page')).trigger('change');
        });

        $('#booking-choose-datetime').on('click','#date-selector .previous-button',function(event){
            var button = $(event.currentTarget);
            $('#page').val(button.data('page')).trigger('change');
        });

        $('#page').on('change',function(event){
            serviceBookingForm.back('dateTimeForm');
            var input = $(event.currentTarget);
            if ('' == input.val()) {
            } else {
                $('#booking-choose-datetime .datetimes').empty();
                App.Utils.loading($('#booking-choose-datetime .datetimes'), 'text-center', 'huge', 200, 200);
                // send request
                self.loadDateTimes();
            }
        });

        $('#booking-choose-datetime').on('click','#time-selector .button.time.column',function(event){
            // click on time button
            var button = $(event.currentTarget);
            serviceBookingForm.back('dateTimeForm');
            $('#time-selector .button.time.column.selected').removeClass('selected');
            button.addClass('selected');
            $('#date').val(button.data('date'));
            $('#time').val(button.data('time')).trigger('change');
        });

        $('#time').on('change',function(event){
            var input = $(event.currentTarget);
            if (self.isValid()) {
                $('#booking-choose-datetime').addClass('success');
                serviceBookingForm.next('dateTimeForm');
            } else {
                if ($('#booking-choose-datetime').hasClass('success')) {
                    $('#booking-choose-datetime').removeClass('success');
                    serviceBookingForm.back('dateTimeForm');
                }
            }
            serviceBookingForm.process();
        });

    };

    DateTimeForm.prototype.loadDateTimes = function() {
        var formUrl = $('#booking-choose-datetime').data('url');
        var form = $('#booking-form');
        $.ajax({
            type: "POST",
            dataType: 'html',
            url: formUrl,
            data: form.serialize(),
            success: function (data) {
                $('#booking-choose-datetime .datetimes').empty();
                $('#booking-choose-datetime .datetimes').html(data);
                var currentPage = $('#date-selector').data('current-page');
                // do not trigger here!
                $('#page').val(currentPage);
            },
            error: function (data) {
            }
        });
    };

    DateTimeForm.prototype.initStep = function() {
        this.block.addClass('active');
        var onlineBooking = $('#garage_online_booking').val();
        $('#booking-choose-datetime .datetimes').empty();
        if (1 == onlineBooking) {
            // activate datetime box and send ajax request
            App.Utils.loading($('#booking-choose-datetime .datetimes'), 'text-center', 'huge', 200, 200);
            $('#booking-choose-datetime .offline').addClass('invisible');
            $('#booking-choose-datetime .online').removeClass('invisible');
            this.loadDateTimes();
            App.Utils.scrollTo(self.block, -50);
        } else {
            // no online datetime booking in this service, goto the next step
            $('#booking-choose-datetime .online').addClass('invisible');
            $('#booking-choose-datetime .offline').removeClass('invisible');
            serviceBookingForm.next('dateTimeForm');
        }
    };

    DateTimeForm.prototype.backStep = function() {
        $('#booking-choose-datetime').removeClass('active');
        $('#date').val('').trigger('change');
        $('#time').val('').trigger('change');
    };

    var self = new DateTimeForm();
    serviceBookingForm.addForm("dateTimeForm", self);

})(jQuery, App, serviceBookingForm);

// booking preview block
(function ($,App,serviceBookingForm) {

    function BookingPreview() {
        this.block = $('#booking-preview');
    }

    BookingPreview.prototype.isValid = function() {
        return true;
    };

    BookingPreview.prototype.init = function() {};

    BookingPreview.prototype.initStep = function() {
        // fill preview box
        var garageText = $('#booking-choose-garage .garage.selected').data('preview');
        $('#booking-preview .garage').html(garageText);

        var productText = $('#booking-choose-service .service.selected').data('preview');
        $('#booking-preview .product').html(productText);

        var datetimeText = $('#time-selector .time.selected').data('preview');
        if (!datetimeText) {
            datetimeText = $('#booking-choose-datetime .offline').data('title');
        }
        $('#booking-preview .datetime').html(datetimeText);

        var carText = $('#brand').val();
        var carType = $('#type').val();
        var result = carType.match("(.+)?([0-9]{4})(-[0-9]{2})? - ([^\(]+)(\(.+\))?","gu");
        if (result) {
            carText = carText + ' ' + result[4] + ' ' + result[2];
        } else {
            carText = carText + ' ' + carType;
        }

        $('#booking-preview .car').html(carText);

        $('#booking-preview').removeClass('invisible');
        serviceBookingForm.process();
        App.Utils.scrollTo(self.block, -50);
    };

    BookingPreview.prototype.backStep = function() {
        $('#booking-preview .garage').html('');
        $('#booking-preview .product').html('');
        $('#booking-preview .datetime').html('');
        $('#booking-preview').addClass('invisible');
    };

    var self = new BookingPreview();
    serviceBookingForm.addForm("bookingPreview", self);

})(jQuery, App, serviceBookingForm);



// Note: this file need to be end this block
// main service booking form
(function ($,App,serviceBookingForm) {

    if ($('body').hasClass('page-booking')) {
        serviceBookingForm.init();
    }

})(jQuery, App, serviceBookingForm);
