(function ($) {
    // newsletter

    $('#newsletter-subscribe-form').on('submit', function (event) {
        var postData = $(this).serializeArray();
        var formURL = $(this).attr("action");

        $('#newsletter-widget-modal').modal({
            transition: 'fade up',
            onShow: function(){
                $('#newsletter-widget-modal .message').hide();
                $('#newsletter-widget-modal .message.working').show();
            }
        }).modal('show');

        $.ajax({
            type: "POST",
            dataType: 'json',
            url: formURL,
            data: postData,
            success: function (data) {
                $('#newsletter-widget-modal .message').hide();
                $('#newsletter-widget-modal .message.success').show();
                $('#newsletter-subscribe-form').find('input').val('');

                // follower code
                if (typeof data.content_js != 'undefined' && data.content_js.length) {
                    $('head').append($('<script>', {
                        type: 'text/javascript',
                        html: data.content_js
                    }));
                }
            },
            error: function (data) {
                $('#newsletter-widget-modal .message').hide();
                $('#newsletter-widget-modal .message.error').show();
            }
        });

        event.preventDefault();
        return false;
    });

})(jQuery);

(function($, App) {
    // quantity stepper functions

    App.addQuantityStepperEvents = function(parent) {
        parent = parent || 'body';

        $(parent).on('click', '.quantity-stepper .quantity-up', function(event) {
            var wrapper = $(event.currentTarget).parents('.quantity-stepper');
            var input = wrapper.find('.quantity');
            var quantity = input.val();
            quantity++;
            input.val(quantity).trigger('change');
        });

        // quantity decrease
        $(parent).on('click', '.quantity-stepper .quantity-down', function(event) {
            var wrapper = $(event.currentTarget).parents('.quantity-stepper');
            var input = wrapper.find('.quantity');
            var quantity = input.val();
            if (1 < quantity) {
                quantity--;
                input.val(quantity).trigger('change');
            }
        });
    };

    App.addQuantityStepperEvents();
})(jQuery, App);

(function ($,App) {
    // product page

    // other sizes dropdown
    $('.other-sizes .dropdown').dropdown();

    // status popup
    $('.storage-status').popup({
        inline     : true,
        hoverable  : true,
        position   : 'bottom left'
    });

    // refresh the counter on page load
    $('.product-cart-wrapper .product-quantity-input').trigger('change');

    // add to cart button modal
    $('#add-to-cart-modal').modal({
        observeChanges: true,
        transition: 'scale'
    });
    $('.add-to-cart-form').on('submit', function(event){
        event.preventDefault();
        // begin to show the modal in loading state
        $('#add-to-cart-modal').addClass('working').modal('show');
        // send data to the cart
        var form = $(event.currentTarget);
        var productSku = form.data('product-sku');
        var input = form.find('#quantity-'+productSku);
        var quantity = input.val();
        App.Cart.add(productSku,quantity,
            function(status, data) {
                if (status) {
                    var unitPrice = input.data('unit-price');
                    var locale = input.data('locale');
                    var currency = input.data('currency');
                    $('#add-to-cart-modal .product-amount .value').text(quantity);
                    var displayTotal = $('#add-to-cart-modal .total-price');
                    displayTotal.text(App.Utils.formatCurrency(quantity * unitPrice, currency, locale));
                    // extend url
                    var url = $('#add-to-cart-modal .goto-service-booking-url').data('href');
                    $('#add-to-cart-modal .goto-service-booking-url').prop('href',url + data.key);
                    $('#add-to-cart-modal').removeClass('working');
                }
            }
        );
    });

})(jQuery,App);


(function ($,App) {
    $('.ordering-select').dropdown();
})(jQuery);

(function($, App) {
    var eprelId = $('#qr-code-panel').data("eprel_id");
    if (eprelId) {
        var QRious = require('qrious');
        new QRious({
            element: document.getElementById('qr-code-panel'),
            value: 'https://eprel.ec.europa.eu/qr/' + eprelId,
            size: 60
        });
    }


})(jQuery, App);


(function ($) {
    var webviewRegExp = new RegExp(
        '(WebView|(iPhone|iPod|iPad)(?!.*Safari)|Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})|Linux; U; Android)',
        'ig'
    );
    if (navigator.userAgent.match(webviewRegExp)) {
        $('a[target="_blank"]').removeAttr('target');
    }
})(jQuery);
